
// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

// const TopNav = () => {
//   return (
//     <nav className="w-full py-1 border-b" style={{ background: '#002C54' }}>
//       <div className="flex flex-col md:flex-row justify-between px-4 md:px-20 items-center font-semibold">
//         {/* Uncomment the following div if you have a logo */}
//         {/* <div>
//           <h1 className="text-2xl">LOGO</h1>
//         </div> */}
//         <div className="flex flex-col md:flex-row xl:gap-10 md:gap-8 gap-2 items-center">
//           <a href="#" className="text-white hover:text-[#E3CEA3] flex items-center gap-1 mb-2 md:mb-0">
//             <FontAwesomeIcon icon={faMobileAlt} className="text-xl" />
//             <span>+91 9148788100</span>
//           </a>
//           <a href="#" className="text-white hover:text-[#E3CEA3] flex items-center gap-1 mb-2 md:mb-0">
//             <FontAwesomeIcon icon={faEnvelope} className="text-xl" />
//             <span>newmacaulayschool2012@gmail.com</span>
//           </a>
//         </div>
//         <div className="flex flex-col md:flex-row gap-2 mt-2 md:mt-0 items-center">
//           <button className="py-2 px-6 bg-white text-black rounded-3xl font-semibold hover:bg-orange-600">
//             School Info
//           </button>
//           <button className="py-2 px-6 bg-white text-black rounded-3xl font-semibold hover:bg-orange-600">
//             Mandatory Public Disclosure
//           </button>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default TopNav;


// src/components/TopNav.js


// src/components/TopNav.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const TopNav = () => {
  return (
    <nav className="w-full py-2 border-b" style={{ background: '#002C54' }}>
      <div className="flex flex-col md:flex-row justify-between px-4 md:px-20 items-center font-semibold">
        <div className="flex flex-col md:flex-row xl:gap-10 md:gap-8 gap-2 items-center">
          <a href="#" className="text-white hover:text-[#E3CEA3] flex items-center gap-1 mb-2 md:mb-0 items-center">
            <FontAwesomeIcon icon={faMobileAlt} className="text-xl" />
            <span>+91 9148788100</span>
          </a>
          <a href="#" className="text-white hover:text-[#E3CEA3] flex items-center gap-1 mb-2 md:mb-0 items-center">
            <FontAwesomeIcon icon={faEnvelope} className="text-xl" />
            <span>newmacaulayschool2012@gmail.com</span>
          </a>
        </div>
        <div className="flex flex-col md:flex-row gap-2 mt-2 md:mt-0 items-center">
          {/* <button className="py-2 px-6 bg-white text-black rounded-3xl font-semibold hover:bg-orange-600 flex items-center justify-center" style={{marginTop: '0'}}>
            School Info
          </button> */}
          <a href="/school">
  <button className="py-2 px-6 bg-white text-red-600 rounded-3xl font-semibold hover:bg-orange-600 flex items-center justify-center" style={{marginTop: '0'}}>
    School Info
  </button>
</a>

          {/* <button className="py-2 px-6 bg-white text-black rounded-3xl font-semibold hover:bg-orange-600 flex items-center justify-center" style={{marginTop: '0'}}>
            Mandatory Public Disclosure
          </button> */}
          <a href="/mpddetails">
  <button className="py-2 px-6 bg-white text-red-600 rounded-3xl font-semibold hover:bg-orange-600 flex items-center justify-center" style={{marginTop: '0'}}>
    Mandatory Public Disclosure
  </button>
</a>

        </div>
      </div>
    </nav>
  );
}

export default TopNav;
