import React from 'react'
// import achihero from '../../assets/Quiz Competition.jpeg'
import achihero from '../../assets/KarateinAchievers.jpg'

const Achihero = () => {
  return (
    <>
    <div className="herotrial">
        <div className="herotrial__bg">
          <picture>
            <img src={ achihero} alt="Hero Background" width={1500} />
          </picture>
        </div>

        <div className="herotrial__cnt">
          <h1><span style={{ color: 'white' }}>New Macaulay English School</span></h1>
          <h1><span style={{ color: 'white' }}>"Knowledge Is supreme"</span></h1>
        </div>
      </div>
    </>
  )
}

export default Achihero