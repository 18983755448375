import React from 'react'
// import fhero from '../../assets/foundationabout.jpg'
import fhero from '../../assets/event.svg'

const Fhero = () => {
  return (
    <>
      <div className="herotrial">
        <div className="herotrial__bg">
          <picture>
            <img src={fhero} alt="Hero Background" width='1500px'/>
          </picture>
        </div>

        <div className="herotrial__cnt">
          <h1><span style={{ color: 'white' }}>New Macaulay English School</span></h1>
          <h1><span style={{ color: 'white' }}>"Knowledge Is supreme"</span></h1>
        </div>
      </div>
    </>
  )
}

export default Fhero