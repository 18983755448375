import React from 'react'
import Navbar from '../component/Upgraded/Navbar'
import Acedemics from '../component/acedemics/Index'
import Teachers from '../component/management/Teachers'
import Courosol from '../component/Upgraded/Courosol'
import Footer from '../component/footer/Index'
import Acehero from '../component/hero/Acehero'

const AcedemicsDetails = () => {
  return (
    <>
      <Navbar />
      <Acehero/>
      <Acedemics />
      <Courosol />
      <Teachers />
      <Footer />
    </>
  )
}

export default AcedemicsDetails