import React from 'react'
import './fac.css'

const Fac = () => {
    return (
        <>
            <div className='mt-5'>
                <div class="nine">
                    <h1>Our Amenities<span>Facilities</span></h1>
                </div>
            </div>
            <div className='fac'>

                <div class="ag-format-container">
                    <div class="ag-courses_box">
                        <div class="ag-courses_item">
                            <div class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    Classroom
                                </div>
                                <div class="ag-courses-item_date-box">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5z"
                                        />
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5z"
                                        />
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5z"
                                        />
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M5 9l7-4m7 4l-7 4m0 0l7-4m-7 4v6"
                                        />
                                    </svg>
                                    <span class="ag-courses-item_date">Our classrooms are designed to foster creativity and collaboration. Equipped with interactive whiteboards and ergonomic furniture, they provide a comfortable space.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="ag-courses_item">
                            <div class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>

                                <div class="ag-courses-item_title">
                                    karate
                                </div>


                                <div class="ag-courses-item_date-box">

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7h-2m0 0a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2V9a2 2 0 00-2-2z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 17l6-6h4l6 6M12 2v10" />
                                    </svg>


                                    <span class="ag-courses-item_date">  we offer a comprehensive karate program as part of our curriculum. Through karate education, students not only learn self-defense techniques but also cultivate discipline, focus, respect.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="ag-courses_item">
                            <div class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>


                                <div class="ag-courses-item_title">

                                    Computer Labs
                                </div>


                                <div class="ag-courses-item_date-box">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        class="h-6 w-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M5 4h14a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2z"
                                        />
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14v8m-4-4h8"
                                        />
                                    </svg>
                                    <span class="ag-courses-item_date">  In our computer labs, students develop essential digital skills. High-speed internet access and up-to-date software enable them to explore coding, multimedia design, and research projects.

                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="ag-courses_item">
                            <div class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>

                                <div class="ag-courses-item_title">

                                    Library
                                </div>


                                <div class="ag-courses-item_date-box">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        class="h-6 w-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 4v16m-4-8h8M3 21a2 2 0 01-2-2V5a2 2 0 012-2h18a2 2 0 012 2v14a2 2 0 01-2 2H3z"
                                        />
                                    </svg>
                                    <span class="ag-courses-item_date">  Our library is a treasure trove of knowledge with a vast collection of books, e-books, journals, and multimedia resources. It's a quiet oasis for research, reading, and intellectual exploration.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="ag-courses_item">
                            <div class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>

                                <div class="ag-courses-item_title">
                                    Sports Facilities

                                </div>


                                <div class="ag-courses-item_date-box">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        class="h-6 w-6"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zM5 9h14"
                                        />
                                    </svg>
                                    <span class="ag-courses-item_date">We believe in the holistic development of our students, which is why we offer top-notch sports facilities. From basketball courts to soccer fields and indoor gyms, there's something for every sports.
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="ag-courses_item">
                          
                            <div div class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>

                                <div class="ag-courses-item_title">
                                    Transportation

                                </div>


                                <div class="ag-courses-item_date-box">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        className="h-6 w-6 text-gray-700 dark:text-gray-300 hover:text-blue-950"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M19 14l-7 7m0 0l-7-7m7 7V3"
                                        />
                                    </svg>
                                    <span class="ag-courses-item_date">Ensuring safe & convenient transportation for students is a top priority. We offer a comprehensive transportation system that includes well-maintained buses driven by experienced drivers.
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Fac