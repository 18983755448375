import React from 'react'
import '../Upgraded/heading.css';

const TdStyle = {
    ThStyle: `w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-medium text-white lg:py-7 lg:px-4`,
    TdStyle: `text-dark border-b border-l border-[#E8E8E8] bg-[#F3F6FF] dark:bg-dark-3 dark:border-dark dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdStyle2: `text-dark border-b border-[#E8E8E8] bg-white dark:border-dark dark:bg-dark-2 dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdButton: `inline-block px-6 py-2.5 border rounded-md border-primary text-primary hover:bg-primary hover:text-white font-medium`,
};


const StaffDetails = () => {
    return (
        <>
            <div>
                <div class="one">
                    <h1>Staff Details</h1>
                </div>
                <section className='bg-white dark:bg-dark py-20 lg:py-[120px]'>
                    <div className='container'>
                        <div className='flex flex-wrap -mx-4'>
                            <div className='w-full '>
                                <div className='max-w-full overflow-x-auto'>
                                    <table className='w-full table-auto'>
                                        <thead className='text-center' style={{ backgroundColor: ' rgb(10, 68, 127)' }}>
                                            <tr>
                                                <th className={TdStyle.ThStyle}>Information </th>
                                                <th className={TdStyle.ThStyle}> Details </th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className={TdStyle.TdStyle}>Principal</td>
                                                <td className={TdStyle.TdStyle2}>Balaji R</td>

                                            </tr>
                                            <tr>
                                                <td className={TdStyle.TdStyle}>Total No. of Teachers</td>
                                                <td className={TdStyle.TdStyle2}>28</td>

                                            </tr>
                                            <tr>
                                                <td className={TdStyle.TdStyle}>PGT</td>
                                                <td className={TdStyle.TdStyle2}></td>

                                            </tr>
                                            <tr>
                                                <td className={TdStyle.TdStyle}>PRT</td>
                                                <td className={TdStyle.TdStyle2}></td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>TGT</td>
                                                <td className={TdStyle.TdStyle2}></td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>PTI</td>
                                                <td className={TdStyle.TdStyle2}></td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Librarian</td>
                                                <td className={TdStyle.TdStyle2}>01</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Teachers Section Ratio</td>
                                                <td className={TdStyle.TdStyle2}></td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Details of Special Educators</td>
                                                <td className={TdStyle.TdStyle2}></td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Details Of Counselling And Wellness Teacher</td>
                                                <td className={TdStyle.TdStyle2}></td>

                                            </tr>



                                            <tr>
                                                <td className={TdStyle.TdStyle}>Computer Teacher	</td>
                                                <td className={TdStyle.TdStyle2}>10 TOILETS</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>No. of Boy's Toilets</td>
                                                <td className={TdStyle.TdStyle2}>12 TOILETS 12 URINALS</td>

                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

export default StaffDetails