import React from 'react'
import './heading.css'
import ansika from '../../assets/ansika.JPG'
import sowmya from '../../assets/sowmya.JPG'
import shital from '../../assets/shital.JPG'
import dipika from '../../assets/dipika.JPG'



const Toppers = () => {
    return (
        <>

            <div class="nine">
                <h1>Our Toppers<span>Leaders</span></h1>
            </div>


            <section id="Projects"
                class="w-fit mx-auto grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5">

                <div class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
                    style={{ border: 'none', borderRadius: '60px 10px 60px 10px' }}>

                    <div>
                        <img src={ansika}
                            alt="Product" class="h-80 w-72 object-cover rounded-t-xl" />
                        <div class="px-4 py-3 w-72">
                            <span class="text-gray-400 mr-3 uppercase text-xs">10th</span>
                            <p class="text-lg font-bold text-black truncate block capitalize">ANSIKA YADAV S</p>
                            <div class="flex items-center">
                                <p class="text-lg font-semibold text-black cursor-auto my-3">95 %</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
                    style={{ border: 'none', borderRadius: '60px 10px 60px 10px' }}>

                    <div>
                        <img src={sowmya}
                            alt="Product" class="h-80 w-72 object-cover rounded-t-xl" />
                        <div class="px-4 py-3 w-72">
                            <span class="text-gray-400 mr-3 uppercase text-xs">10th</span>
                            <p class="text-lg font-bold text-black truncate block capitalize">SOWMYA I MATH</p>
                            <div class="flex items-center">
                                <p class="text-lg font-semibold text-black cursor-auto my-3">93 %</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
                    style={{ border: 'none', borderRadius: '60px 10px 60px 10px' }}>
                    <div>
                        <img src={shital}
                            alt="Product" class="h-80 w-72 object-cover rounded-t-xl" />
                        <div class="px-4 py-3 w-72">
                            <span class="text-gray-400 mr-3 uppercase text-xs">10th</span>
                            <p class="text-lg font-bold text-black truncate block capitalize">SHEETAL SHARMA</p>
                            <div class="flex items-center">
                                <p class="text-lg font-semibold text-black cursor-auto my-3">91 %</p>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
                    style={{ border: 'none', borderRadius: '60px 10px 60px 10px' }}>
                    <div>
                        <img src={dipika}
                            alt="Product" class="h-80 w-72 object-cover rounded-t-xl" />
                        <div class="px-4 py-3 w-72">
                            <span class="text-gray-400 mr-3 uppercase text-xs">10th</span>
                            <p class="text-lg font-bold text-black truncate block capitalize">DEEPIKA L</p>
                            <div class="flex items-center">
                                <p class="text-lg font-semibold text-black cursor-auto my-3">90 %</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

           
            <div className='b' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <a href="/achieversdetails" class="b">
                    <span class="shadow"></span>
                    <span class="edge"></span>
                    <span class="front text">See More</span>
                </a>
            </div>
        </>
    )
}

export default Toppers