import React, { useRef } from 'react';
import '../intro/enquiry.css';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    alert('Form submitted successfully!'); // Show alert when form is submitted

    emailjs
      .sendForm('service_p6thwi8', 'template_0vs81un', form.current, 'LP1HYs_DeA6ODU7NB')
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <>
      <section className="bg-white" style={{ fontFamily: 'open-sans', backgroundColor: 'coral' }}>
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
          <div className="nine">
            <h1>Get in Touch with Us<span>Contact</span></h1>
          </div>
          <p className="mb-8 lg:mb-16 font-light text-center text-black sm:text-xl" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
            Feel free to reach out to us using the contact form below. We welcome your questions, feedback, and inquiries about our programs and services.
          </p>
          <form className="space-y-8 contact-validation-active" ref={form} onSubmit={sendEmail}>
            <div>
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">Your Name</label>
              <input
                type="text"
                name="name"
                id="name"
                className="shadow-sm border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                placeholder="Your name"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your Email</label>
              <input
                type="email"
                name="email"
                id="email"
                className="shadow-sm border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                placeholder="name@example.com"
                required
              />
            </div>
            <div>
              <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900">Subject</label>
              <input
                type="text"
                name="subject"
                id="subject"
                className="block p-3 w-full text-sm text-gray-900 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                placeholder="Let us know how we can help you"
                required
              />
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Your Message</label>
              <textarea
                name="message"
                id="message"
                rows="6"
                className="block p-2.5 w-full text-sm text-gray-900 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Leave a comment..."
                required
              ></textarea>
            </div>
            <button type="submit" className="relative">
              <span className="shadow"></span>
              <span className="edge"></span>
              <span className="front text">Submit</span>
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
