import React, { useState } from 'react'
import './heading.css'
import legecy from '../../assets/Discover.JPG'


const WhyMacauly = () => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <div className='mt-5'>
                <div class="nine">
                    <h1>Discover Our Legacy<span>History</span></h1>
                </div>
            </div>

            <div className='why' style={{ backgroundColor: 'orange', textAlign: 'center', padding: '40px' }}>


                <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                        <div className="lg:col-span-4 mt-10 lg:mt-0" style={{ padding: '10px' }}>
                            <img class="rounded-circle" alt="avatar1" src={ legecy} width='600px' />

                        </div>

                        <div className="lg:col-span-3" style={{ marginBottom: '10px' }}>
                            <div
                                className={`p-4 rounded-lg shadow-md hover:shadow-xl transition-transform duration-300 ease-in-out ${isHovered ? 'transform scale-105' : ''}`}
                                style={{ borderRadius: '20px', backgroundColor: '#002C54' }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <h1 className="block text-3xl font-bold text-white sm:text-2xl md:text-3xl lg:text-4xl dark:text-white">Transforming Education:</h1>
                                <p className="mt-3 text-lg text-white dark:text-white">Empowering Young Minds for a Brighter Future.</p>
                                <div className="mt-6 lg:mt-12">
                                    <div className="mt-4">
                                        <div>
                                            <ul className="list-none flex flex-col gap-4">
                                                <li className="block text-3xl font-bold text-white sm:text-2xl md:text-2xl lg:text-3xl dark:text-white">Academic Excellence</li>
                                                <hr style={{ color: 'white', border: 'solid dark 50px' }} />
                                                <li className="block text-3xl font-bold text-white sm:text-2xl md:text-2xl lg:text-3xl dark:text-white">Innovative Learning</li>
                                                <hr style={{ color: 'white', border: 'solid dark 50px' }} />
                                                <li className="block text-3xl font-bold text-white sm:text-2xl md:text-2xl lg:text-3xl dark:text-white">Holistic Development</li>
                                                <hr style={{ color: 'white', border: 'solid dark 50px' }} />
                                                <li className="block text-3xl font-bold text-white  sm:text-2xl md:text-2xl lg:text-3xl dark:text-white" style={{ marginBottom: '10px' }}>Community and Support</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default WhyMacauly