import React from 'react';
import './herotrial.css';
import heroimg from '../../assets/Home.JPG'

const HeroTrial = () => {
  return (
    <>
      <div className="herotrial">
        <div className="herotrial__bg">
          <picture>
            <img src={heroimg } alt="Hero Background" />
          </picture>
        </div>

        <div className="herotrial__cnt">
          <h1><span style={{ color: 'white' }}>New Macaulay English School</span></h1>
          <h1><span style={{ color: 'white' }}>"Knowledge Is supreme"</span></h1>
        </div>
      </div>
    </>
  );
};

export default HeroTrial;
