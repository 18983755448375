import React from 'react'
import Navbar from '../component/Upgraded/Navbar'
import Enquiry from '../component/intro/Enquiry'
import AdmissionIntro from '../component/intro/AdmissionIntro'
import Footer from '../component/footer/Index'
import Adhero from '../component/hero/Adhero'


const Admission = () => {
  return (
    <div>
      <Navbar />
      <Adhero/>
      <AdmissionIntro />
      <Enquiry />
      <Footer />
    </div>
  )
}

export default Admission
