import React from 'react'
import Navbar from '../component/Upgraded/Navbar'
import GallaryMain from '../component/gallary/Index'
import Footer from '../component/footer/Index'
import Ghero from '../component/hero/Ghero'

const Gallary = () => {
  return (
    <>
      <Navbar />
      <Ghero/>
      <GallaryMain />
      <Footer />
    </>
  )
}

export default Gallary