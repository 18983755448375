import React from 'react'
import Navbar from '../component/Upgraded/Navbar'
import Contact from '../component/contact/Index'
import Footer from '../component/footer/Index'
import Chero from '../component/hero/Chero'

const ContactUs = () => {
  return (
    <>
      <Navbar />
      <Chero/>
      <Contact />
      <Footer />
    </>
  )
}

export default ContactUs