import './introduction.css'
import React, { useEffect, useState } from 'react';
import f1 from '../../assets/foundation2.jpeg'
import f2 from '../../assets/founation1.JPG'
import dm from '../../assets/dm3.JPG'


const Introduction = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Triggering the animation after a delay for demonstration
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  }, []);
  return (

    <>


     <div className=''>
     <section className="bg-white dark:bg-gray-900">
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light text-black-500 sm:text-lg dark:text-black-400">
            <h2 className="mb-4 ml-5 text-4xl tracking-tight font-extrabold text-black-900 dark:text-black hover:text-blue-900">Continuing the Legacy: Macaulay School's Timeless Commitment to Excellence</h2>
            <p className="mb-4 ml-5" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>At Macaulay School, our philosophy centers on building upon a strong foundation rather than reinventing the wheel. We believe in honoring the timeless traditions of quality education while also embracing innovation and modernization. Our approach combines the best practices of the past with the latest advancements in teaching and learning, ensuring that our students receive a comprehensive and relevant education.</p>
            <p className="mb-4 ml-5" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>By staying true to our roots while adapting to the changing educational landscape, Macaulay School continues to uphold its legacy of excellence and remains a beacon of academic achievement and personal growth for generations to come.</p>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-8">
            <img
              className={`w-full rounded-lg ${isVisible ? 'animate-bounce' : ''}`}
              src={f1}
              alt="office content 1"
              style={{ height: '150%', objectFit: 'cover' }}
            />
            <img
              className={`mt-4 w-full lg:mt-10 rounded-lg ${isVisible ? 'animate-float' : ''}`}
              src={f2}
              alt="office content 2"
              style={{ height: '150%', objectFit: 'cover' }}
            />
          </div>

        </div>
      </section>

      <div className='in' style={{ backgroundColor: '#FDF6F6', paddingTop: '5px', paddingBottom: '5px' }}>
        <div className="container my-24 mx-auto md:px-6">
          <section className="mb-32">
            <h2 className="mb-12 text-center text-3xl font-bold hover:text-blue-900">
              Discover the Macaulay difference:
              Why Macaulay?
            </h2>

            <div className="flex flex-wrap items-center">
              <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:px-6 lg:mb-0 lg:w-5/12">
                <div className="relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                  data-te-ripple-init data-te-ripple-color="light">
                  <img src={dm} className="w-full" alt='school' style={{ height: '500px' }} />
                  <a href="#!">
                    <div
                      className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,0%,0.4)] bg-fixed">
                      <div className="flex h-full items-center justify-center ">
                        <div className="px-6 py-12 text-center text-white md:px-12">
                          <h3 className="mb-6 text-2xl font-bold uppercase hover:text-red-600">
                            The Future Is Here
                            {/* <u className="text-[hsl(210,12%,80%)]">Here</u> */}
                          </h3>
                          <p className="text-[white]">
                            Experience the Macaulay difference: personalized support, transformative education, and boundless opportunities for success.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="relative overflow-hidden bg-cover bg-no-repeat">
                      <div
                        className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div className="w-full shrink-0 grow-0 basis-auto md:px-6 lg:w-7/12">
                <div className="mb-12 flex">
                  <div className="shrink-0">
                    <div className="rounded-md bg-[hsl(204,30%,20%)]  p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" className="h-6 w-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
                      </svg>
                    </div>


                  </div>
                  <div className="ml-4 grow">
                    <p className="mb-1 font-bold hover:text-blue-900" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Personalized Support</p>
                    <p className="text-neutral-500 dark:text-black-300 hover:text-black" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                      We understand that each student is unique, with their own strengths, challenges, and learning styles. That's why we are committed to providing personalized support that meets the individual needs of every student.
                    </p>
                  </div>
                </div>

                <div className="mb-12 flex">
                  <div className="shrink-0">
                    <div className="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" className="h-6 w-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4 grow">
                    <p className="mb-1 font-bold hover:text-blue-900" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Education With Skills</p>
                    <p className="text-neutral-500 dark:text-black-300 hover:text-black" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                      Our curriculum integrates academic excellence with hands-on experiences, fostering critical thinking, problem-solving, communication, and collaboration skills.
                    </p>
                  </div>
                </div>

                <div className="mb-12 flex">
                  <div className="shrink-0">
                    <div className="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" className="h-6 w-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4 grow">
                    <p className="mb-1 font-bold hover:text-blue-900" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Inclusive Environment</p>
                    <p className="text-neutral-500 dark:text-black-300 hover:text-black" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                      We strive to foster a sense of belonging and respect for diversity among our students, staff, and community. Our commitment to inclusivity goes beyond mere acceptance; it's about celebrating differences.
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <div className="shrink-0">
                    <div className="rounded-md bg-[hsl(204,30%,20%)] p-4 shadow-lg dark:bg-[hsl(204,23%,30%)]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" className="h-6 w-6 text-white">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4 grow">
                    <p className="mb-1 font-bold hover:text-blue-900" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Diverse Curriculum</p>
                    <p className="text-neutral-500 dark:text-black-300 hover:text-black" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                      Our school takes pride in offering a diverse curriculum that reflects the richness of our global society. We believe that exposure to a wide range of subjects, perspectives, and cultural contexts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
     </div>
    </>
  )
}

export default Introduction