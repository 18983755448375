import React from 'react'
import '../Upgraded/heading.css';


const TdStyle = {
    ThStyle: `w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-medium text-white lg:py-7 lg:px-4`,
    TdStyle: `text-dark border-b border-l border-[#E8E8E8] bg-[#F3F6FF] dark:bg-dark-3 dark:border-dark dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdStyle2: `text-dark border-b border-[#E8E8E8] bg-white dark:border-dark dark:bg-dark-2 dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdButton: `inline-block px-6 py-2.5 border rounded-md border-primary text-primary hover:bg-primary hover:text-white font-medium`,
}

const Mpd = () => {

    const pdfUrls = [
        { title: 'Copies of Affiliation Letter', url: '' },
        { title: 'Copies of Trust Deed', url: 'https://drive.google.com/file/d/13waxJfYXHqFNI60P3xDmudOoefq1fbfA/view?usp=sharing' },
        { title: 'Copy of No Objection Certificate (NOC) Issued, by The State Govt. /UT', url: 'https://drive.google.com/file/d/14MRrGRd2TSbBp-uE3TFaDMAXNOwi3rPC/view?usp=sharing' },
        { title: 'Copy of Recognition Certificate	', url: 'https://drive.google.com/file/d/13vK_UGROgDXE4-jfv53_Ed07ZnVLy8Gf/view?usp=sharing' },
        { title: 'Copy of valid Building safety Certificate issued by Department of Public Works', url: 'https://drive.google.com/file/d/13xcCAIsixZDMfx6H36Rfqq9hBjVM5J0-/view?usp=sharing' },
        { title: 'Copies of Valid Fire Safety Certificate Issued by the Competent Authority', url: '' },
        { title: 'Copies of Valid Water, Health And Sanitation Certificates	', url: '' },
        { title: 'Copies of Land Certificates', url: '' },
        { title: 'Self Declaration', url: 'https://drive.google.com/file/d/14292UHDQ7XmzptAlQlU-nC1oJ78XwP6Q/view?usp=sharing' },

    ];

    const handleViewDocuments = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };

    return (
        <>
            <div>
                <div>
                    <div className="nine mt-5">
                        <h1>Mandatory Public Disclosure<span>Details</span></h1>
                    </div>
                </div>
                <div>
                    <div class="one">
                        <h1>GENERAL INFORMATION</h1>
                    </div>


                    <section className='bg-white dark:bg-dark py-20 lg:py-[120px]'>
                        <div className='container'>
                            <div className='flex flex-wrap -mx-4'>
                                <div className='w-full '>
                                    <div className='max-w-full overflow-x-auto'>
                                        <table className='w-full table-auto'>
                                            <thead className='text-center' style={{ backgroundColor: ' rgb(10, 68, 127)' }}>
                                                <tr>
                                                    <th className={TdStyle.ThStyle}>Information </th>
                                                    <th className={TdStyle.ThStyle}> Details </th>

                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td className={TdStyle.TdStyle}>Contact Details (Landline/Mobile)</td>
                                                    <td className={TdStyle.TdStyle2}>+91 9148788100</td>

                                                </tr>
                                                <tr>
                                                    <td className={TdStyle.TdStyle}>School Code (If Applicable)</td>
                                                    <td className={TdStyle.TdStyle2}>-</td>

                                                </tr>
                                                <tr>
                                                    <td className={TdStyle.TdStyle}>Affiliation No. (If Applicable)</td>
                                                    <td className={TdStyle.TdStyle2}>-</td>

                                                </tr>
                                                <tr>
                                                    <td className={TdStyle.TdStyle}>Name of The School</td>
                                                    <td className={TdStyle.TdStyle2}>New Macaulay English School</td>

                                                </tr>

                                                <tr>
                                                    <td className={TdStyle.TdStyle}>Complete Address With Pin Code</td>
                                                    <td className={TdStyle.TdStyle2}>Sarjapura Road, Sompura gate-562125, Banglore.</td>

                                                </tr>

                                                <tr>
                                                    <td className={TdStyle.TdStyle}>School E-mail Id</td>
                                                    <td className={TdStyle.TdStyle2}>newmacaulayschool2012@gmail.com</td>

                                                </tr>

                                                <tr>
                                                    <td className={TdStyle.TdStyle}>Principal Name & Qualification</td>
                                                    <td className={TdStyle.TdStyle2}>Balaji R</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>

               
               <div>
                    <div class="one">
                        <h1>DOCUMENTS AND INFORMATION</h1>
                    </div>
                </div>


                <section className='bg-white dark:bg-dark py-20 lg:py-[120px]'>
                    <div className='container'>
                        <div className='flex flex-wrap -mx-4'>
                            <div className='w-full '>
                                <div className='max-w-full overflow-x-auto'>
                                    <table className='w-full table-auto'>
                                        <thead className='text-center' style={{backgroundColor:' rgb(10, 68, 127)'}}>
                                            <tr>
                                                <th className={TdStyle.ThStyle}> Information </th>
                                                <th className={TdStyle.ThStyle}> View documents </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {pdfUrls.map((pdf, index) => (
                                                <tr key={index}>
                                                    <td className={TdStyle.TdStyle}>{pdf.title}</td>
                                                    <td className={TdStyle.TdStyle2}>
                                                        <span
                                                            className={TdStyle.TdButton}
                                                            onClick={() => handleViewDocuments(pdf.url)}
                                                        >
                                                            View Document {index + 1}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
       </div>

     
        </>
    )
}

export default Mpd