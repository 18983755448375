import React from 'react';
import '../Upgraded/heading.css';

const TdStyle = {
    ThStyle: `w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-medium text-white lg:py-7 lg:px-4`,
    TdStyle: `text-dark border-b border-l border-[#E8E8E8] bg-[#F3F6FF] dark:bg-dark-3 dark:border-dark dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdStyle2: `text-dark border-b border-[#E8E8E8] bg-white dark:border-dark dark:bg-dark-2 dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdButton: `inline-block px-6 py-2.5 border rounded-md border-primary text-primary hover:bg-primary hover:text-white font-medium`,
};

const SchoolInfo = () => {
    const pdfUrls = [
        { title: 'School Calendar', url: 'https://drive.google.com/file/d/14XG6Ap1AmGKo2YjoIve2sT8K19On5mLr/view' },
        { title: 'Fee Structure	', url: 'https://drive.google.com/file/d/148NMa2fg4irZHdGFSa1KULY63ukULM_o/view?usp=sharing' },
        { title: 'Affidavit', url: '' },
        { title: 'School Infrastructure Details', url: '' },
    ];

    const handleViewDocuments = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };

    return (
        <>
            <div>
                <div className="nine mt-5">
                    <h1>School Info<span>Details</span></h1>
                </div>

                <section className='bg-white dark:bg-dark py-20 lg:py-[120px]'>
                    <div className='container'>
                        <div className='flex flex-wrap -mx-4'>
                            <div className='w-full '>
                                <div className='max-w-full overflow-x-auto'>
                                    <table className='w-full table-auto'>
                                        <thead className='text-center' style={{backgroundColor:' rgb(10, 68, 127)'}}>
                                            <tr>
                                                <th className={TdStyle.ThStyle}> Title </th>
                                                <th className={TdStyle.ThStyle}> View documents </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {pdfUrls.map((pdf, index) => (
                                                <tr key={index}>
                                                    <td className={TdStyle.TdStyle}>{pdf.title}</td>
                                                    <td className={TdStyle.TdStyle2}>
                                                        <span
                                                            className={TdStyle.TdButton}
                                                            onClick={() => handleViewDocuments(pdf.url)}
                                                        >
                                                            View Document {index + 1}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default SchoolInfo;
