import React from 'react'
import AboutSub from '../component/about/AboutSub'
import Navbar from '../component/Upgraded/Navbar'
import Highlights from '../component/intro/Highlights'
import Info from '../component/intro/Info'
import Footer from '../component/footer/Index'
import Ahero from '../component/hero/Ahero'

const AboutUs = () => {
  return (
    <>
     <Navbar/>
     <Ahero/>
    <Info/>
    <Highlights/>
    <AboutSub/>
    <Footer/>
    </>
  )
}

export default AboutUs