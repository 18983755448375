// import React from 'react'
// import principal from '../../assets/principal.jpg'
// import './teachers.css'

// const Principal = () => {
//   return (
//     <>
    
//     <div class="container my-24 mx-auto md:px-6 y">
//   <section class="mb-32">
//     <div
//       class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
//       <div class="flex flex-wrap items-center">
//         <div class="hidden shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12">
//           <img src={principal}
//             class="w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
//             alt='' />
//         </div>
//         <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
//           <div class="px-6 py-12 md:px-12">
//             <h2 class="mb-4 text-2xl font-bold" >
//             "Welcome to Macaulay English High school: Empowering Excellence, Inspiring Futures            </h2>
//             <p class="mb-6 flex items-center font-bold uppercase text-amber dark:text-amber-600">
//               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
//                 stroke="currentColor" class="mr-2 h-5 w-5">
//                 <path stroke-linecap="round" stroke-linejoin="round"
//                   d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
//                 <path stroke-linecap="round" stroke-linejoin="round"
//                   d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
//               </svg>
//               Principal's Note</p>
//             <p class="mb-6 text-black dark:text-black" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
//             Welcome to our school website! As the Principal, it gives me great pleasure to extend a warm greeting to all students, parents, and visitors. Our mission at New Macaulay English School is to nurture and inspire excellence in every student, preparing them for a successful future. Through our dedicated faculty, innovative programs, and a supportive community, we aim to empower each individual to reach their full potential. I invite you to explore our website to learn more about our academic offerings, extracurricular activities, and the vibrant learning environment we provide. Together, let's embark on a journey of learning, growth, and achievement.
//             </p>
//             <p class="text-black dark:text-black" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
//             "Cultivating a sense of community is at the heart of our school's ethos. We believe that strong partnerships between school, home, and the wider community are essential for a well-rounded education. Our doors are always open, and we encourage active participation from parents and community members in school activities and initiatives. By working together, we can create a supportive and inclusive environment where every student feels valued, respected, and empowered to thrive. I look forward to building meaningful connections with each of you and witnessing the incredible growth and achievements of our students at New Macaulay English School."
//             <br />

//             <span style={{color: 'orange', lineHeight: '35px', fontSize: '1.2rem'}}>Balaji R</span>
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
// </div>
//     </>
//   )
// }

// export default Principal

import React from 'react';
import principal from '../../assets/principal.jpg';
import './teachers.css';

const Principal = () => {
  return (
    <div className="container my-24 mx-auto md:px-6">
      <section className="mb-32">
        <div className="block rounded-lg bg-white shadow-md dark:bg-neutral-700">
          <div className="flex flex-wrap items-center">
            <div className="w-full lg:w-6/12 xl:w-4/12">
              <img
                src={principal}
                className="w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
                alt="Principal"
              />
            </div>
            <div className="w-full lg:w-6/12 xl:w-8/12">
              <div className="px-6 py-12 md:px-12">
                <h2 className="mb-4 text-2xl font-bold">
                  Welcome to Macaulay English High school: Empowering Excellence, Inspiring Futures
                </h2>
                <p className="mb-6 flex items-center font-bold uppercase text-amber dark:text-amber-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="mr-2 h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
                    />
                  </svg>
                  Principal's Note
                </p>
                <p className="mb-6 text-black dark:text-black" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                  Welcome to our school website! As the Principal, it gives me great pleasure to extend a warm greeting
                  to all students, parents, and visitors. Our mission at New Macaulay English School is to nurture and
                  inspire excellence in every student, preparing them for a successful future. Through our dedicated
                  faculty, innovative programs, and a supportive community, we aim to empower each individual to reach
                  their full potential. I invite you to explore our website to learn more about our academic offerings,
                  extracurricular activities, and the vibrant learning environment we provide. Together, let's embark on
                  a journey of learning, growth, and achievement.
                </p>
                <p className="text-black dark:text-black" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                  Cultivating a sense of community is at the heart of our school's ethos. We believe that strong
                  partnerships between school, home, and the wider community are essential for a well-rounded education.
                  Our doors are always open, and we encourage active participation from parents and community members in
                  school activities and initiatives. By working together, we can create a supportive and inclusive
                  environment where every student feels valued, respected, and empowered to thrive. I look forward to
                  building meaningful connections with each of you and witnessing the incredible growth and achievements
                  of our students at New Macaulay English School.
                  <br />
                  <span style={{ color: 'orange', lineHeight: '35px', fontSize: '1.2rem' }}>Balaji R</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Principal;
