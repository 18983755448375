import React from 'react'
import '../Upgraded/heading.css'


const AdmissionIntro = () => {
  return (
    <>
      <section Class="bg-white dark:bg-gray-900">
        <div Class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
        
          <div>
            <div Class="nine">
              <h1>Explore Our School's Admission Details<span>Now</span></h1>
            </div>
          </div>
          <p Class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Enquiring about school admission is the first step towards a promising educational journey. We understand the importance of finding the right educational environment that nurtures your child's potential and fosters holistic development. Our school's admission process is designed to be transparent and supportive, providing parents and guardians with all the necessary information and guidance they need. From academic requirements to extracurricular activities, our admissions team is here to answer your questions and assist you every step of the way. We invite you to reach out to us for more details on admission criteria, application deadlines, and any other inquiries you may have. Together, let's create a foundation for your child's future success and happiness.</p>


        </div>
      </section>
    </>
  )
}

export default AdmissionIntro
