import React from 'react'
import img1 from '../../assets/preprimary.JPG'
import '../Upgraded/heading.css'


const Info = () => {
  return (
    <>
      <div class="flex flex-wrap" style={{ marginTop: '50px', marginBottom: '50px' }}>
        <div class="w-full sm:w-8/12 mb-10">
          <div class="container mx-auto h-full sm:p-10">
            <nav class="flex px-4 justify-between items-center">
              <div class="text-4xl font-bold  hover:text-orange-600 " style={{fontSize: '3.5rem'}}>
                Acedemics<span class="text-blue-900">.</span>
              </div>
              <div>
                <img src="https://image.flaticon.com/icons/svg/497/497348.svg" alt="" class="w-8" />
              </div>
            </nav>
            <header class="container px-4 lg:flex mt-10 items-center h-full lg:mt-0">
              <div class="w-full">
                <h1 class="text-4xl lg:text-6xl font-bold"> Igniting Minds <span class="text-blue-900">Inspiring</span> Excellence</h1>
                <div class="w-20 h-2 bg-blue-900 my-4"></div>
                <p class="font-normal text-lg leading-6 text-gray-600 mt-6" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>At Macaulay School, our academic journey begins in nursery and extends all the way to 10th grade, encompassing a comprehensive and enriching learning experience. In our nursery program, we focus on fostering a love for learning through play-based activities, exploration, and social interaction.</p>
             
                <div className='b' style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', marginTop: '20px' }} >
                <a href="/acedemicsdetails" class="b">
                    <span class="shadow"></span>
                    <span class="edge"></span>
                    <span class="front text">Learn More</span>
                </a>
            </div>
              </div>
            </header>
          </div>
        </div>
        <img src={img1} alt="Leafs" class="w-full h-48 object-cover sm:h-screen sm:w-4/12" />
      </div>
    </>
  )
}

export default Info