import React from 'react'
import shero from '../../assets/schoolinfo.JPG'

const Shero = () => {
  return (
    <>
    <div className="herotrial">
        <div className="herotrial__bg">
          <picture>
            <img src={ shero} alt="Hero Background" />
          </picture>
        </div>

        <div className="herotrial__cnt">
          <h1><span style={{ color: 'white' }}>New Macaulay English School</span></h1>
          <h1><span style={{ color: 'white' }}>"Knowledge Is supreme"</span></h1>
        </div>
      </div>
    </>
  )
}

export default Shero