import React from 'react'
import Navbar from '../component/Upgraded/Navbar'
import Footer from '../component/footer/Index'
import Mpd from '../component/schoolinfo/Mpd'
import AcedemicsDocs from '../component/schoolinfo/AcedemicsDocs';
import SchoolInfra from '../component/schoolinfo/SchoolInfra'
import StaffDetails from '../component/schoolinfo/StaffDetails'
import Puhero from '../component/hero/Puhero'

const MpdDetails = () => {
  return (
    <>
    <Navbar/>
    <Puhero/>
    <Mpd/>
    <AcedemicsDocs/>
    <SchoolInfra/>
    <StaffDetails/>
    <Footer/>
    </>
  )
}

export default MpdDetails