import React from 'react'
import '../Upgraded/heading.css'
import chairman from '../../assets/chairman.JPG'
import secretory from '../../assets/secretory.jpeg'

const Management = () => {
    return (
        <>
          <div className='mt-5'>
          <div class="nine">
                <h1>Our Chairman<span>Visionary</span></h1>
            </div>

          <section class="pt-10 overflow-hidden bg-gray-50 dark:bg-white-800 md:pt-0 sm:pt-16 2xl:pt-16" style={{paddingBottom: '30px', paddingTop: '30px'}}>
                <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div class="grid items-center grid-cols-1 md:grid-cols-2">

                        <div>
                            <h2 class="text-3xl font-bold leading-tight text-black dark:text-white sm:text-4xl lg:text-5xl">Hey I
                                am
                                <br class="block sm:hidden" /> Mr.Jayashankar
                            </h2>
                            <p class="max-w-lg mt-3 text-xl leading-relaxed text-black dark:text-black md:mt-8" style={{ lineHeight: '35px', fontSize: '1.2rem' }} >
                                As the <span style={{color: 'orange'}}>Chairman of New Macaulay English School</span>, I am deeply committed to providing a nurturing and enriching environment for our students, faculty, and staff. Our school's mission is to foster academic excellence, character development, and a love for lifelong learning.

                                I work closely with the school's leadership team to ensure that our educational programs are innovative, inclusive, and aligned with the needs of our students. We prioritize creating a safe and supportive atmosphere where every student can thrive academically, socially, and emotionally.

                                Through strategic planning, community engagement, and a focus on continuous improvement, we aim to empower our students to become confident, responsible, and compassionate individuals prepared to make a positive impact in our global society.


                            </p>


                        </div>

                        <div class="relative">
                        <img class="relative w-full xl:max-w-lg xl:mx-auto 2xl:origin-bottom 2xl:scale-110" src={chairman} alt="" />

                        </div>

                    </div>
                </div>
            </section>
          </div>
<div className='mt-5'>
<div class="nine">
                <h1>Our Secretary<span>Organizer</span></h1>
            </div>
</div>
            <section class="pt-10 overflow-hidden bg-gray-50 dark:bg-gray-600 md:pt-0 sm:pt-16 2xl:pt-16" style={{paddingBottom: '30px', paddingTop: '30px', backgroundColor: 'orange'}}>
                <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl"> 
                    <div class="grid items-center grid-cols-1 md:grid-cols-2">

                        <div>
                            <h2 class="text-3xl mt-5 font-bold leading-tight text-white dark:text-white sm:text-4xl lg:text-5xl">Hey I am
                                <br class="block sm:hidden" /> Mrs. Rekha
                            </h2>
                            <p class="max-w-lg mt-3 text-xl leading-relaxed text-white dark:text-gray-300 md:mt-8" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                                As the  <span style={{color: 'darkblue'}}>Secretory of New macaulay Engllish School</span>, I am proud to share that our curriculum is meticulously designed to cultivate not just academic prowess but also essential life skills. We prioritize hands-on experiences through project-based learning, internships, and mentorship initiatives, empowering our students to excel in critical thinking, problem-solving, and effective communication. Our mission is to prepare each student for a bright future filled with confidence, competence, and endless possibilities.
                            </p>
                        </div>

                        <div class="relative">
                        <img class="relative w-full xl:max-w-lg xl:mx-auto 2xl:origin-bottom 2xl:scale-110" src={secretory} alt="" />

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Management