import React from 'react'
import Navbar from '../component/Upgraded/Navbar'
import Toppers from '../component/Upgraded/Toppers'
import Campus from '../component/Upgraded/Campus'
import WhyMacauly from '../component/Upgraded/WhyMacauly'
import MacaulayBest from '../component/Upgraded/MacaulayBest'
import Studentsfeed from '../component/Upgraded/Studentsfeed'
import About from '../component/about/Index'
import HeroTrial from '../component/hero/HeroTrial'
import Footer from '../component/footer/Index'

const Upgraded = () => {
  return (
    <>
      <Navbar />
      <HeroTrial />
      <About />
      <Toppers />
      <WhyMacauly />
      <Campus />
      <MacaulayBest />
      <Studentsfeed />
      <Footer />
    </>
  )
}

export default Upgraded