import React from 'react'
import Navbar from '../component/Upgraded/Navbar'
import Principal from '../component/management/Principal'
import Footer from '../component/footer/Index'
import Phero from '../component/hero/Phero'

const PrincipalDetails = () => {
  return (
    <>
      <Navbar />
      <Phero/>
      <Principal />
      <Footer />
    </>
  )
}

export default PrincipalDetails