import React from 'react';
import '../Upgraded/heading.css';
import g22 from '../../assets/g22.JPG';  // Example image
import g11 from '../../assets/g11.jpeg';
import g3 from '../../assets/g3.JPG';
import g4 from '../../assets/dance4.jpg';
import g5 from '../../assets/g5.jpeg';
import g6 from '../../assets/g6.JPG';
import g7 from '../../assets/g7.JPG';
import g8 from '../../assets/dance3.jpg';
import g2 from '../../assets/g2.jpeg';
import g1 from '../../assets/g1.JPG';
import g9 from '../../assets/g9.JPG';
import g10 from '../../assets/g10.JPG';
import g12 from '../../assets/g12.jpg';
import g14 from '../../assets/g14.JPG';
import g15 from '../../assets/g15.JPG';
import g16 from '../../assets/g16.JPG';
import g17 from '../../assets/g17.jpeg';
import g18 from '../../assets/g18.JPG';
import g19 from '../../assets/g19.JPG';
import g20 from '../../assets/g20.jpeg';
import g21 from '../../assets/g21.JPG';
import g13 from '../../assets/g13.JPG';
import g23 from '../../assets/Raksha.JPG';
import g25 from '../../assets/g25.JPG';
import gheroVideo from '../../assets/gallary-video.mp4';  // Example video
import gheroVideos from '../../assets/gallary-videos.mp4';  // Example video
// import playIcon from '../../assets/play-icon.svg';  // Example play button icon

const GallaryMain = () => {
  return (
    <>
      <div className='mt-5'>
        <div className="nine">
          <h1>Our Gallery<span>Moments</span></h1>
        </div>
      </div>
      <section className="bg-white dark:bg-gray-900 ">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-blue-900">"Explore Our School's Vibrant Journey: A Gallery of Memories"</h1>
            <p className="max-w-2xl mb-6 font-light text-black-500 lg:mb-8 md:text-lg lg:text-xl dark:text-black-400" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Welcome to our school's gallery page! Here, we showcase the vibrant and diverse activities that happen on our campus. Explore the images below to get a glimpse of our students' talents, achievements, and memorable moments.</p>
            <div className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-black rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
              Gallery
              <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={g22} alt="mockup" />
          </div>
        </div>
      </section>

      {/* Video Section */}

      <div className='mt-1'>
        <div className="nine">
          <h1>Glimpse of Events<span>Moments</span></h1>
        </div>
      </div>

      {/* <div className="grid grid-cols-1 gap-4 md:grid-cols-2 mb-5">
        <div className="relative">
          <video className="h-auto max-w-full rounded-lg object-cover object-center" src={gheroVideo} controls />
          <div className="absolute bottom-0 left-0 w-full p-4 bg-black bg-opacity-50 text-white">
            <h2 className="text-lg font-bold">Macaulay Events</h2>
           
          </div>
        </div>
        <div className="relative">
          <video className="h-auto max-w-full rounded-lg object-cover object-center" src={gheroVideos} controls />
          <div className="absolute bottom-0 left-0 w-full p-4 bg-black bg-opacity-50 text-white">
            <h1 className="text-lg font-bold">Investiture Ceremony</h1>
            
          </div>
        </div>
      </div> */}


<div>
      {/* First two videos with original style */}
      <div className="grid grid-cols-2 gap-4 mb-5">
        <div className="relative">
          <video
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src={gheroVideo}
            controls
          />
          <div className="absolute bottom-0 left-0 w-full p-4 bg-black bg-opacity-50 text-white">
            <h2 className="text-lg font-bold">Macaulay Events</h2>
          </div>
        </div>
        <div className="relative">
          <video
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src={gheroVideos}
            controls
          />
          <div className="absolute bottom-0 left-0 w-full p-4 bg-black bg-opacity-50 text-white">
            <h1 className="text-lg font-bold">Investiture Ceremony</h1>
          </div>
        </div>
      </div>

      {/* Responsive grid of iframes */}
      <div className="grid grid-cols-1 gap-4 mb-5 md:grid-cols-2 lg:grid-cols-3">
        {/* Next three iframes with increased height */}
        <div className="relative h-96 w-full">
          <iframe
            className="h-full w-full rounded-lg object-cover object-center"
            src="https://www.youtube.com/embed/bUjT5RfN5d0?si=JfH4CPos6jx2rCOU"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <div className="absolute bottom-0 left-0 w-full p-4 bg-black bg-opacity-50 text-white">
            <h2 className="text-lg font-bold">Doctors Day</h2>
          </div>
        </div>
        <div className="relative h-96 w-full">
          <iframe
            className="h-full w-full rounded-lg object-cover object-center"
            src="https://www.youtube.com/embed/NQIsXImvMvc?si=UDURmh6VCiBD2eQ3"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <div className="absolute bottom-0 left-0 w-full p-4 bg-black bg-opacity-50 text-white">
            <h1 className="text-lg font-bold">Krishna Janmashtami</h1>
          </div>
        </div>
        <div className="relative h-96 w-full">
          <iframe
            className="h-full w-full rounded-lg object-cover object-center"
            src="https://www.youtube.com/embed/xXi-KNO7GU8?si=SySS75UNvKFvKt68"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <div className="absolute bottom-0 left-0 w-full p-4 bg-black bg-opacity-50 text-white">
            <h2 className="text-lg font-bold">Field Trip</h2>
          </div>
        </div>
      </div>
    </div>


    


      {/* Images Section */}

      <div className="grid grid-cols-2 gap-4 md:grid-cols-4 mb-5">
        <div className="grid gap-4">
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g1} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g11} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g2} alt="" />
          </div>
        </div>
        <div className="grid gap-4">
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g12} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g4} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g5} alt="" />
          </div>
        </div>
        <div className="grid gap-4">
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g6} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g7} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g8} alt="" />
          </div>
        </div>
        <div className="grid gap-4">
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g9} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g3} alt="" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        <div className="grid gap-4">
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g10} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g13} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g14} alt="" />
          </div>
        </div>
        <div className="grid gap-4">
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g15} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g16} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g17} alt="" />
          </div>
        </div>
        <div className="grid gap-4" style={{ marginBottom: '50px' }}>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g18} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g19} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g20} alt="" />
          </div>
        </div>
        <div className="grid gap-4">
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g21} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g25} alt="" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg object-cover object-center" src={g23} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default GallaryMain;
