import React from 'react'
// import phero from '../../assets/3.Environment day(5.6.22).jpg'
import phero from '../../assets/Achievers2.JPG'

const Phero = () => {
  return (
    <>
     <div className="herotrial">
        <div className="herotrial__bg">
          <picture>
            <img src={phero} alt="Hero Background" />
          </picture>
        </div>

        <div className="herotrial__cnt">
          <h1><span style={{ color: 'white' }}>New Macaulay English School</span></h1>
          <h1><span style={{ color: 'white' }}>"Knowledge Is supreme"</span></h1>
        </div>
      </div>
    </>
  )
}

export default Phero