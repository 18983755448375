import React from 'react'
import './Fe.css'
import math from '../../assets/math.jpg'
import science from '../../assets/science.jpg'
import library from '../../assets/library.jpg'
import comp from '../../assets/Comp Lab.jpg'
import sport from '../../assets/sports.JPG'
import art from '../../assets/arts.JPG'

const Fe = () => {
    return (
        <>
            <div className='fe'>
                <h1 style={{ color: 'white' }}>Explore Our Academic Offerings Chart Your Path to Success</h1>
                <div class="cards">
                    <div class="card">
                        <h2 class="card-title">Psychology</h2>
                        <img src={library} alt="" />
                        <p class="card-desc" >Our school is designed to provide students with a profound understanding of the human mind and behavior. Through a comprehensive curriculum, students delve into various branches of psychology, including developmental, cognitive, social.</p>
                    </div>

                    <div class="card">
                        <h2 class="card-title">Sports</h2>
                        <img src={sport} alt="" />
                        <p class="card-desc">Sports education in our school is a holistic approach to physical fitness, skill development, teamwork, and sportsmanship. Our curriculum combines structured training with opportunities for competitive play, recreational activities, and personal growth.</p>
                    </div>

                    <div class="card">
                        <h2 class="card-title">Fine Arts</h2>
                        <img src={art} alt="" />
                        <p class="card-desc">Fine arts education at our school is a vibrant and inspiring journey that nurtures creativity, self-expression, and artistic exploration. Our curriculum encompasses a wide range of disciplines, including visual arts, performing arts.</p>
                    </div>

                    <div class="card">
                        <h2 class="card-title">Mathematics</h2>
                        <img src={math} alt="" />
                        <p class="card-desc">Mathematics education at our school is a dynamic and engaging journey that fosters critical thinking, problem-solving skills, and a deep understanding of mathematical concepts.</p>
                    </div>

                    <div class="card">
                        <h2 class="card-title">Science</h2>
                        <img src={science} alt="" />
                        <p class="card-desc">Our comprehensive curriculum covers a wide range of scientific disciplines, including biology, chemistry, physics, earth science, environmental science, Through hands-on experiments.</p>
                    </div>



                    <div class="card">
                        <h2 class="card-title">Technology</h2>
                        <img src={comp} alt="" />
                        <p class="card-desc">Technology education at our school is a dynamic and forward-thinking experience that equips students with essential digital skills, creativity, and innovation mindset.</p>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Fe