import React from 'react';
import Marquee from 'react-fast-marquee';
import './corousal.css';
import nikhat from '../../assets/nikhat.JPG'
import ansika from '../../assets/ansika.JPG'
import sowmya from '../../assets/sowmya.JPG'
import shital from '../../assets/shital.JPG'
import dipika from '../../assets/dipika.JPG'


const toppers = [
  {
    id: "0",
    image: ansika,
    percentage: "95% ",
    name: 'ANSIKA YADAV S',
    year: "2022-2023",
  },
  {
    id: "1",
    image: sowmya,
    percentage: "93% ",
    name: 'SOWMYA I MATH',
    year: "2022-2023",
  },
  
  {
    id: "2",
    image: shital,
    percentage: "91 %",
    name: 'SHEETAL SHARMA',
    year: "2022-2023",
  },
  {
    id: "3",
    image: dipika,
    percentage: "90 %",
    name: 'DEEPIKA L',
    year: "2022-2023",
  },
  
  {
    id: "4",
    image: nikhat,
    percentage: "90 %",
    name: 'NIKHATH SABA',
    year: "2022-2023",
  },

 
 
];

const Courosol = () => {
  return (
    <>
     <div className='mt-5'>
          <div class="nine">
            <h1>Our Success Stories<span>DISTINCT</span></h1>
          </div>
        </div>
      <section className="admissionMarquee topmarq">
        <Marquee>
          {toppers.map(function(data) {
            return (
              <div className="card studentCard inlblock"  key={data.id}>
                <img className="img" src={data.image} alt="" />
                <span>{data.name}</span>
                <p className="percentage">{data.percentage}</p>
                <p className="year">{data.year}</p>
              </div>
            );
          })}
        </Marquee>
      </section>
    </>
  );
};

export default Courosol;
