import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faUsers, faGraduationCap, faSchool, faBuilding, faChalkboard, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/MHS.png';
import './navbar.css';

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <div className='navtest'>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid navcontainer">
            <a href="/" className="navbar-logo">
              <img className="navbar-logo-logo" src={logo} alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div className={`navbar-collapse ${showMenu ? 'show' : ''}`}>
              <ul className="navbar-nav ms-auto">
                <li className="nav-item mainmenu">
                  <a href="/" className="nav-link" aria-current="page">
                    <span><FontAwesomeIcon icon={faHome} /> Home</span>
                  </a>
                </li>
                <li className="nav-item mainmenu dropdown">
                  <a
                    className="nav-link mainmenua dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span><FontAwesomeIcon icon={faUsers} /> About Us</span>
                  </a>
                  <ul className="dropdown-menu dropdwnmenu">
                    <li>
                      <a href="/aboutus" className="nav-link dropdwnmenua">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="/Foundation" className="nav-link dropdwnmenua">
                        Foundational Pillars
                      </a>
                    </li>
                    <li>
                      <a href="/managementpage" className="nav-link dropdwnmenua">
                        Management
                      </a>
                    </li>
                    <li>
                      <a href="/principaldesk" className="nav-link dropdwnmenua">
                        Principal Desk
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item mainmenu dropdown">
                  <a
                    className="nav-link mainmenua dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span><FontAwesomeIcon icon={faGraduationCap} /> Academics</span>
                  </a>
                  <ul className="dropdown-menu dropdwnmenu">
                    <li>
                      <a href="/acedemicsdetails" className="nav-link dropdwnmenua">
                        Our Academics
                      </a>
                    </li>
                    <li>
                      <a href="/achieversdetails" className="nav-link dropdwnmenua">
                        Achievers
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item mainmenu">
                  <a href="/admission" className="nav-link mainmenua">
                    <span><FontAwesomeIcon icon={faSchool} /> Admission</span>
                  </a>
                </li>
                <li className="nav-item mainmenu dropdown">
                  <a
                    className="nav-link mainmenua dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span><FontAwesomeIcon icon={faBuilding} /> Facilities</span>
                  </a>
                  <ul className="dropdown-menu dropdwnmenu">
                    <li>
                      <a href="/facilitiespage" className="nav-link dropdwnmenua">
                        Our Facilities
                      </a>
                    </li>
                    <li>
                      <a href="/gallary" className="nav-link dropdwnmenua">
                        Gallery
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item mainmenu dropdown">
                  <a
                    className="nav-link mainmenua dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span><FontAwesomeIcon icon={faChalkboard} /> School Info</span>
                  </a>
                  <ul className="dropdown-menu dropdwnmenu">
                    <li>
                      <a href="/school" className="nav-link dropdwnmenua">
                        School Info
                      </a>
                    </li>
                    <li>
                      <a href="/mpddetails" className="nav-link dropdwnmenua">
                        Mandatory Public Disclosure
                      </a>
                    </li>
                  </ul>
                </li> */}
                <li className="nav-item mainmenu">
                  <a href="/contactus" className="nav-link mainmenua">
                    <span><FontAwesomeIcon icon={faEnvelope} /> Contact Us</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className='reg' style={{ textAlign: 'center', fontWeight: 'bolder', color: 'red', marginTop: '10px', marginBottom: '10px', }}>
        Affiliated to CBSE, New Delhi,
        Affiliation No.123456
      </div>
    </>
  );
};

export default Navbar;

