import React from 'react'
import './heading.css'
import quiz from '../../assets/q.svg'
import teachers from '../../assets/event.svg'
import smartclass from '../../assets/ds.svg'
import ea from '../../assets/ga.svg'

const programData = [
    {
        title: 'Academic Programs',
        content: [
            'CBSE-Aligned Curriculum',
            'Experiential Learning Opportunities: ',
            'Subject Diversity'
        ],
        imageUrl: quiz,
    },
    {
        title: 'Faculty Excellence',
        content: [
            'Highly Qualified Faculty',
            'Subject Matter Experts',
            'Parent-Teacher Communication'
        ],
        imageUrl: teachers,
    },
    {
        title: 'Technology Integration',
        content: [
            'Smart classrooms',
            'Digital Learning Platforms',
            'Technology-Enabled Assessments'
        ],
        imageUrl: smartclass,
    },
    {
        title: 'Extracurricular Activities',
        content: [
            'Sports Excellence',
            'Arts and Music Programs',
            'Cultural Celebrations'
        ],
        imageUrl:  ea,
    },
];


const MacaulayBest = () => {
    return (
        <>
            <div className='best' style={{ marginTop: '-70px', marginBottom: '0' }}>
                <div>
                    <div class="nine">
                        <h1>Macaulay<span>The Best</span></h1>
                    </div>
                </div>
                <div className="macaulay-best p-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4" >
                        {programData.map((program, index) => (
                            <div key={index} className="rounded-xl p-6 md:p-8" style={{ background: '#002C54' }}>
                                <img
                                    className="w-full h-auto rounded-t-lg mb-4 hover:opacity-75"
                                    src={program.imageUrl}
                                    alt=""
                                />
                                <div className="bg-white p-4 rounded-b-lg">
                                    <h5 className="mb-2 text-2xl font-bold text-black ">{program.title}</h5>
                                    <ul className="text-black dark:text-black-400">
                                        {program.content.map((item, i) => (
                                            <li key={i} className="mb-2">{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default MacaulayBest