import React from 'react'
import Navbar from '../component/Upgraded/Navbar'
import Fac from '../component/acedemics/Fac'
import Footer from '../component/footer/Index'
import Facihero from '../component/hero/Facihero'


const FacilityPage = () => {
  return (
    <>
      <Navbar />
      <Facihero/>
      <Fac />
      <Footer />
    </>
  )
}

export default FacilityPage