import React from 'react'
import chero from '../../assets/contact.jpg'

const Chero = () => {
  return (
    <>
     <div className="herotrial">
        <div className="herotrial__bg">
          <picture>
            <img src={ chero} alt="Hero Background" />
          </picture>
        </div>

        <div className="herotrial__cnt">
          <h1><span style={{ color: 'white' }}>New Macaulay English School</span></h1>
          <h1><span style={{ color: 'white' }}>"Knowledge Is supreme"</span></h1>
        </div>
      </div>
    </>
  )
}

export default Chero