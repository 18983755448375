import React, { useEffect } from 'react'
import './acedemics.css'
import '../Upgraded/heading.css'
import pre from '../../assets/pre1.JPG'
import pri from '../../assets/pri.JPG'
import middle from '../../assets/middle.JPG'
import sec from '../../assets/secondary.JPG'



const Acedemics = () => {
    useEffect(() => {
        const elements = document.querySelectorAll('.animate-left-to-right');
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animation-triggered');
                } else {
                    entry.target.classList.remove('animation-triggered');
                }
            });
        });

        elements.forEach(element => {
            observer.observe(element);
        });

        // Clean up
        return () => {
            observer.disconnect();
        };
    }, []); // Empty dependency array means this effect runs only once after the component mounts

    return (
        <>
            <div className='' style={{ backgroundColor: '#FDF6F6' }}>
                <div class="text-center p-8">

                    <div class="nine">
                        <h1>Welcome to Our Academics<span>Programs</span></h1>
                    </div>

                    <div class="flex flex-wrap items-center mt-20  text-center">
                        <div class="w-full md:w-3/5 lg:w-1/2 px-4">
                            <img src={pre} alt="gem" class="inline-block rounded shadow-lg border border-merino-400" />
                        </div>
                        

                        <div class="w-full md:w-2/5 lg:w-1/2 px-4 text-center md:text-left lg:pl-12">
                            <h3 class="font-bold mt-8 text-xl md:mt-0 sm:text-2xl hover:text-orange-600">
                                Pre-Primary
                            </h3>

                            <p className="sm:text-lg mt-6 animate-left-to-right" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                                Welcome to our Pre-Primary Program, where every child's journey into learning is filled with wonder, joy, and discovery! Our program is carefully crafted to provide a nurturing and stimulating environment for children aged 3 to 5 years, laying a strong foundation for their academic and personal growth.
                                In our pre-primary classrooms, learning is a joyful adventure. Through play-based activities, interactive games, and hands-on experiences, children explore the world around them while developing essential skills in language, numeracy, social interaction, and emotional intelligence.
                            </p>


                        </div>
                    </div>

                    <div class="flex flex-wrap items-center mt-20  text-center">
                        <div class="w-full md:w-3/5 lg:w-1/2 px-4">
                            <img src={pri} alt="project members" class="inline-block rounded shadow-lg border border-merino-400" />
                        </div>
                        <div class="w-full md:w-2/5 lg:w-1/2 px-4 md:order-first text-center md:text-left lg:pr-12">
                            <h3 class="font-bold mt-8 text-xl md:mt-0 sm:text-2xl hover:text-orange-600">
                                Primary School
                            </h3>
                            <p className="sm:text-lg mt-6 animate-left-to-right" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                                Welcome to our Primary School, where young minds blossom and futures are shaped with knowledge, creativity, and character. Our primary education program is designed to provide a well-rounded learning experience for students aged 6 to 11 years, focusing on academic excellence, critical thinking, and holistic development.
                                In our vibrant classrooms, students embark on a journey of discovery and exploration. Our curriculum blends core subjects such as English, mathematics, science, social studies, and languages with enriching activities in arts, music, sports, and technology. Through hands-on learning, interactive lessons,
                            </p>
                        </div>
                    </div>

                    <div class="flex flex-wrap items-center mt-20   text-center">
                        <div class="w-full md:w-3/5 lg:w-1/2 px-4">
                            <img src={middle} alt="editor" class="inline-block rounded shadow-lg border border-merino-400" />
                        </div>
                        <div class="w-full md:w-2/5 lg:w-1/2 px-4 text-center md:text-left lg:pl-12">
                            <h3 class="font-bold mt-8 text-xl md:mt-0 sm:text-2xl hover:text-orange-600">
                                Middle School
                            </h3>
                            <p className="sm:text-lg mt-6 animate-left-to-right" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                                Welcome to our Middle School, a dynamic and empowering educational environment where students in grades 6 to 8 embark on a transformative journey of academic discovery, personal growth, and preparation for higher learning.
                                In our Middle School, we strive to create a balanced and comprehensive curriculum that challenges and inspires young minds. Our students delve deeper into core subjects such as English, mathematics, science, social studies, and languages, engaging in rigorous coursework that fosters critical thinking, problem-solving, and analytical skills.
                            </p>
                        </div>
                    </div>

                    <div class="flex flex-wrap items-center mt-20  text-center">
                        <div class="w-full md:w-3/5 lg:w-1/2 px-4">
                            <img src={sec} alt="bulk editing" class="inline-block rounded shadow-lg border border-merino-400" />
                        </div>
                        <div class="w-full md:w-2/5 lg:w-1/2 px-4 md:order-first text-center md:text-left lg:pr-12">
                            <h3 class="font-bold mt-8 text-xl md:mt-0 sm:text-2xl hover:text-orange-600">
                                Secondary School
                            </h3>
                            <p className="sm:text-lg mt-6 animate-left-to-right" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
                                Welcome to our Secondary School, a place where academic excellence, personal growth, and future aspirations converge to shape well-rounded individuals ready to make a positive impact on the world. Our program is tailored for students up to 10th, offering a comprehensive and enriching educational experience.
                                At our Secondary School, we believe in preparing students for success in higher education, careers, and life. Our curriculum is designed to be challenging, engaging, and relevant, focusing on core subjects such as English, mathematics, science, social studies, and languages while also providing opportunities for specialization and exploration in various fields of interest.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Acedemics