import React from 'react'
import '../Upgraded/heading.css';

const TdStyle = {
    ThStyle: `w-1/6 min-w-[160px] border-l border-transparent py-4 px-3 text-lg font-medium text-white lg:py-7 lg:px-4`,
    TdStyle: `text-dark border-b border-l border-[#E8E8E8] bg-[#F3F6FF] dark:bg-dark-3 dark:border-dark dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdStyle2: `text-dark border-b border-[#E8E8E8] bg-white dark:border-dark dark:bg-dark-2 dark:text-dark-7 py-5 px-2 text-center text-base font-medium`,
    TdButton: `inline-block px-6 py-2.5 border rounded-md border-primary text-primary hover:bg-primary hover:text-white font-medium`,
};


const SchoolInfra = () => {
    return (
        <>
            <div>
                <div class="one">
                    <h1>SCHOOL INFRASTRUCTURE</h1>
                </div>


                <section className='bg-white dark:bg-dark py-20 lg:py-[120px]'>
                    <div className='container'>
                        <div className='flex flex-wrap -mx-4'>
                            <div className='w-full '>
                                <div className='max-w-full overflow-x-auto'>
                                    <table className='w-full table-auto'>
                                        <thead className='text-center' style={{ backgroundColor: ' rgb(10, 68, 127)' }}>
                                            <tr>
                                                <th className={TdStyle.ThStyle}>Information </th>
                                                <th className={TdStyle.ThStyle}> Details </th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td className={TdStyle.TdStyle}>Total Campus Area of The School (In Square Mtr)	</td>
                                                <td className={TdStyle.TdStyle2}>12345.15 sq .Mts</td>

                                            </tr>
                                            <tr>
                                                <td className={TdStyle.TdStyle}>No. Class Rooms	</td>
                                                <td className={TdStyle.TdStyle2}>23</td>

                                            </tr>
                                            <tr>
                                                <td className={TdStyle.TdStyle}>Size of The Class Rooms (In Square Mtr)</td>
                                                <td className={TdStyle.TdStyle2}>52.79 Sq Mtrs</td>

                                            </tr>
                                            <tr>
                                                <td className={TdStyle.TdStyle}>No. and Size of Laboratories Including Computer Labs (In Sq. Mtr)</td>
                                                <td className={TdStyle.TdStyle2}>03 & 79.34 Sq Mtrs</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Computer Lab - 01</td>
                                                <td className={TdStyle.TdStyle2}>79.34 Sq Mtrs</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Composite Science Lab - 01</td>
                                                <td className={TdStyle.TdStyle2}>79.34 Sq Mtrs</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Chemistry Lab - 01	</td>
                                                <td className={TdStyle.TdStyle2}>79.34 Sq Mtrs</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Biology Lab - 01</td>
                                                <td className={TdStyle.TdStyle2}>79.34 Sq Mtrs</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Maths Lab - 01	</td>
                                                <td className={TdStyle.TdStyle2}>79.34 Sq Mtrs</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Internet Facility (Y/N)		</td>
                                                <td className={TdStyle.TdStyle2}>Yes</td>

                                            </tr>



                                            <tr>
                                                <td className={TdStyle.TdStyle}>No. of Girl's Toilets</td>
                                                <td className={TdStyle.TdStyle2}>10 TOILETS</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>No. of Boy's Toilets</td>
                                                <td className={TdStyle.TdStyle2}>12 TOILETS 12 URINALS</td>

                                            </tr>

                                            <tr>
                                                <td className={TdStyle.TdStyle}>Link of YouTube Video of The Inspection of School Covering The Infrastructure of The School</td>
                                                <td className={TdStyle.TdStyle2}>-</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </>
    )
}

export default SchoolInfra