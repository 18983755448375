import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './pages/AboutUs';
import AcedemicsDetails from './pages/AcedemicsDetails';
import AchieversDetails from './pages/AchieversDetails';
import Upgraded from './pages/Upgraded';
import Foundation from './pages/Foundation'
import FacilityPage from './pages/FacilityPage';
import Admission from './pages/Admission';
import ContactUs from './pages/ContactUs';
import Gallary from './pages/Gallary';
import PrincipalDetails from './pages/PrincipalDetails';
import ManagementPage from './pages/ManagementPage';
import School from './pages/School';
import MpdDetails from './pages/MpdDetails';
import TopNav from './component/Upgraded/TopNav';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <TopNav/>
        <Routes>
          <Route path='/' exact element={<Upgraded />} />
          <Route path='/aboutus' exact element={<AboutUs />} />
          <Route path='/contactus' exact element={<ContactUs />} />
          <Route path='/acedemicsdetails' exact element={<AcedemicsDetails />} />
          <Route path='/achieversdetails' exact element={<AchieversDetails />} />
          <Route path='/foundation' exact element={<Foundation />} />
          <Route path='/facilitiespage' exact element={<FacilityPage />} />
          <Route path='/admission' exact element={<Admission />} />
          <Route path='/gallary' exact element={<Gallary />} />
          <Route path='/principaldesk' exact element={<PrincipalDetails />} />
          <Route path='/managementpage' exact element={<ManagementPage />} />
          <Route path='/school' exact element={<School />} />
          <Route path='/mpddetails' exact element={<MpdDetails />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
