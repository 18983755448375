import React from 'react'
import Footer from '../component/footer/Index'
import Navbar from '../component/Upgraded/Navbar'
import SchoolInfo from '../component/schoolinfo/Index'
import Shero from '../component/hero/Shero'

const School = () => {
    return (
        <>
            <Navbar />
            <Shero/>
            <SchoolInfo />
            <Footer />
        </>
    )
}

export default School