import React from 'react'
import Navbar from '../component/Upgraded/Navbar'
import Introduction from '../component/intro/Introduction'
import Fe from '../component/intro/Fe'
import Footer from '../component/footer/Index'
import Fhero from '../component/hero/Fhero'


const Foundation = () => {
  return (
    <>
    <Navbar/>
    <Fhero/>
    <Introduction/>
    <Fe/>
    <Footer/>
    </>
  )
}

export default Foundation