import React from 'react'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import SchoolIcon from '@mui/icons-material/School';
import CelebrationIcon from '@mui/icons-material/Celebration';
import './highlights.css'
import high from '../../assets/aboutfe.jpeg'

const Highlights = () => {
    return (
        <>
        
            <section className="" style={{ backgroundColor: 'orange' }}>
                <div className="container max-w-xl p-6 mx-auto space-y-12  lg:px-8 lg:max-w-7xl">
                    <div>
                        <h2 className="text-3xl font-bold text-center  text-white sm:text-5xl">Macaulay's Commitment to Academic Excellence</h2>
                        <p className="max-w-3xl mx-auto mt-4 text-xl text-white text-center" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>we can build a generation ready to tackle the challenges and opportunities of the future with confidence and resilience. </p>

                    </div>
                    <div className="grid lg:gap-8 lg:grid-cols-2 text-white lg:items-center ">
                        <div>
                            <div className="mt-4 space-y-12">
                                <div className="flex hover:text-black">
                                    <div className="flex-shrink-0">
                                      
                                        <div class="custom-div bg-blue-900" >
                                            <EmojiEventsIcon sx={{ fontSize: '24px', color: 'primary' }} />
                                        </div>

                                    </div>
                                    <div className="ml-4  ">
                                        <h4 className="text-lg font-medium leadi" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>05+ Awards</h4>
                                        <p className="mt-2" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Discover the pride of our school – a legacy of 05+ prestigious awards that celebrate excellence in education.</p>
                                    </div>
                                </div>
                                <div className="flex hover:text-black">
                                    <div className="flex-shrink-0">
                                        <div className="custom-div bg-blue-900">

                                            <WorkspacesIcon sx={{ fontSize: '24px', color: 'primary' }} /> {/* Adjust size and color as needed */}

                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-medium leadi" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>28+ Teaching Staffs</h4>
                                        <p className="mt-2" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Meet our exceptional team of 28+ dedicated teaching staff members, each committed to shaping the future of education with passion, expertise.</p>
                                    </div>
                                </div>
                                <div className="flex hover:text-black">
                                    <div className="flex-shrink-0">
                                        <div className="custom-div bg-blue-900">

                                            <SchoolIcon sx={{ fontSize: '24px', color: 'primary' }} /> {/* Adjust size and color as needed */}

                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-medium leadi" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>3+ Branches</h4>
                                        <p className="mt-2" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Explore our dynamic network of 3+ branches, each offering a unique educational experience designed to inspire, empower.</p>
                                    </div>
                                </div>
                                <div className="flex hover:text-black">
                                    <div className="flex-shrink-0">
                                        <div className="custom-div bg-blue-900">

                                            <CelebrationIcon sx={{ fontSize: '24px', color: 'primary' }} /> {/* Adjust size and color as needed */}


                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <h4 className="text-lg font-medium leadi" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>12+ Experience </h4>
                                        <p className="mt-2" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>Benefit from our extensive 12+ years of experience in delivering exceptional education and shaping young minds for success. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-hidden="true" className="mt-10 lg:mt-0">
                            <div className="image-container">
                                <img
                                    width="600"
                                    height="600"
                                    src={high}
                                    alt=""
                                    style={{ color: 'transparent' }}
                                    className="image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Highlights