import React from 'react'
import './heading.css'
import nikhat from '../../assets/nikhat.JPG'


const Studentsfeed = () => {
  return (
    <>
    
             <div className='mt-5'>
                <div class="nine">
                    <h1>Voices of New Macaulay English School<span>Students</span></h1>
                </div>
            </div>
     <div className='studentfeed' style={{backgroundColor: 'orange', textAlign: 'center', padding: '40px', fontFamily: 'open-sans'}}>
     <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start" style={{backgroundColor: 'white', padding: '30px', }}>
      
      <div className="lg:w-1/3 lg:mr-8 mb-4 lg:mb-0 lg:ml-8">
    <img
        className="rounded-circle transition-transform duration-500 transform hover:scale-110"
        alt="avatar1"
        src={nikhat}
        width='500px'
    />
</div>
      <div className="lg:w-2/3">
        <p className="font-normal text-lg leading-6 text-gray-600 mt-6" style={{ lineHeight: '35px', fontSize: '1.2rem' }}>
        At our school, I've discovered a vibrant community that nurtures both academic excellence and personal growth. The dedicated teachers here go above and beyond to ensure that every student thrives, not just academically but also in extracurricular pursuits. The range of activities available, from sports to arts and everything in between, has allowed me to explore my interests and develop valuable skills. What sets our school apart is the emphasis on holistic development; it's not just about grades, but about becoming well-rounded individuals ready to face the challenges of the future. I'm grateful for the supportive environment, the friendships I've made, and the countless opportunities that have shaped my journey here.
        </p>
        <br />
        <span style={{color: 'red'}}>NIKHATH SABA</span>
      </div>
    </div>
     </div>
    </>
  )
}

export default Studentsfeed