import React from 'react'
import Achievers from '../component/achievers/Index'
import Navbar from '../component/Upgraded/Navbar'
import Footer from '../component/footer/Index'
import Achihero from '../component/hero/Achihero'

const AchieversDetails = () => {
  return (
    <>
      <Navbar />
      <Achihero/>
      <Achievers />
      <Footer />
    </>
  )
}

export default AchieversDetails