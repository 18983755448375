import React from 'react'
import './Footer.css'
import logo from '../../assets/MHS0.png'

const Footer = () => {
    return (
        <>
            <div className='ft'>
                <footer id="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <a href="index.html"><img src={logo} alt="" className="img-fluid logo-footer" /></a>
                            </div>
                            <div className="col-md-3">
                                <div className="useful-link">
                                    <h2 style={{color: 'white'}}>Useful Links</h2>
                                    <div className="use-links">
                                        <li><a href="/"><i className="fa-solid fa-angles-right"></i> Home</a></li>
                                        <li><a href="/aboutus"><i className="fa-solid fa-angles-right"></i> About Us</a></li>
                                        <li><a href="https://phpstack-1252156-4581702.cloudwaysapps.com/site/login" target='blank'><i className="fa-solid fa-angles-right"></i>School IMS</a></li>
                                        <li><a href="/contactus"><i className="fa-solid fa-angles-right"></i> Contact</a></li>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="social-links" style={{marginLeft: '-70px'}}>
                                    <h2 style={{color: 'white'}}>School Timings</h2>
                                    <div className="social-icons">
                                        <li>08 : 50 AM to 03 : 20 PM (Monday to Friday)</li>
                                        <li>08 : 50 AM to 12 : 30 AM (Saturday)</li>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="address">
                                    <h2 style={{color: 'white'}}>Address</h2>
                                    <img src="./assets/images/about/home_line.png" alt="" className="img-fluid" />
                                    <div className="address-links">
                                        <li className="address1"><i className="fa-solid fa-location-dot"></i>Sompura gate-562125</li>
                                        <li className="phone-item"><i className="fa-solid fa-phone"></i>+91 9148788100</li>
                                        <li className="email-item"><i className="fa-solid fa-envelope"></i>newmacaulayschool2012@gmail.com</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <section id="copy-right">
                    <div className="copy-right-sec">
                        ©Macaulay Group of Schools | Designed & Developed by Drighna Technology pvt Ltd.
                    </div>
                </section>
            </div>
        </>
    )
}

export default Footer
