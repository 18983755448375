import React from 'react'
import './heading.css'
import campus from '../../assets/Transportation.jpeg'


const Campus = () => {
  return (
    <>


      <div className='mt-5'>
        <div class="nine">
          <h1>Our Campus<span>Key Points</span></h1>
        </div>
      </div>
      <div class="container my-24 mx-auto md:px-6" style={{marginTop: '0', marginBottom: '0'}}>
        <section class="mb-32">
          <div class="flex flex-wrap">
            <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
              <div class="flex lg:py-12">
                <img src={campus}
                  class="z-[10] w-full rounded-lg shadow-lg dark:shadow-black/20 lg:ml-[50px]" alt="" />
              </div>
            </div>

            <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
              <div
                class="flex h-full items-center rounded-lg  p-6 text-center text-black lg:pl-12 lg:text-left">
                <div class="lg:pl-12">
                  <h2 class="mb-8 text-3xl font-bold">Let it surprise you</h2>
                  <p class="mb-8 pb-2 lg:pb-0" style={{ fontSize: '1.2rem' }}>
                    "Welcome to a journey of excellence and discovery in our school community. As we embark on this educational adventure together, we are committed to fostering the best teams, delivering top-notch quality, and ensuring unparalleled experiences for every student. "
                  </p>

                  <div class="mx-auto mb-8 flex flex-col md:flex-row md:justify-around xl:justify-start">
                    <p class="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" class="mr-2 h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Best Teachers
                    </p>

                    <p class="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" class="mr-2 h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Best Quality
                    </p>

                    <p class="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" class="mr-2 h-5 w-5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      Best Experience
                    </p>

                    
                  </div>

                  <p style={{ fontSize: '1.2rem', marginBottom: '0' }}>

                    At our school, we believe in cultivating a dynamic learning environment that goes beyond textbooks and classrooms. Our approach integrates real-world experiences, hands-on projects, and collaborative initiatives that empower students to think critically, solve problems creatively, and embrace lifelong learning. 
Through real-world experiences and collaborative projects, students gain practical skills and develop a passion for lifelong learning, preparing them for success in the ever-evolving global landscape.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Campus