import React, { useState, useRef } from 'react';
import './enquiry.css';
import { Button, Modal } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

const Enquiry = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_p6thwi8', 'template_gax69uy', form.current, 'LP1HYs_DeA6ODU7NB')
      .then(
        () => {
          alert('Form submitted successfully!');
          handleClose();
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <>
      <div
        className="flex flex-1 w-full flex-col items-center justify-center text-center px-4 py-20 bg-gradient-to-r from-green-50/50 via-teal-50 to-green-50/50 dark:from-gray-800 dark:via-gray-700 dark:to-gray-800"
        style={{ background: 'orange' }}
      >
        <h2 className="mx-auto max-w-4xl font-display text-5xl font-bold tracking-normal text-white-300 dark:text-white sm:text-7xl">
          Enroll
          <span className="relative whitespace-nowrap text-white-600 dark:text-white"> Now</span>
          <span className="relative whitespace-nowrap text-white-600 dark:text-blue-900">
            <svg
              aria-hidden="true"
              viewBox="0 0 418 42"
              className="absolute top-2/3 left-0 h-[0.58em] w-full fill--500 dark:fill-red-300/60"
              preserveAspectRatio="none"
            >
              <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.780 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.810 23.239-7.825 27.934-10.149 28.304-14.005 .417-4.348-3.529-6-16.878-7.066Z"></path>
            </svg>
            <span className="relative"> 2024-2025</span>
          </span>
        </h2>
        <h2
          className="mx-auto mt-12 max-w-xl text-lg sm:text-white-400 text-white dark:text-gray-300 leading-7"
          style={{ lineHeight: '35px', fontSize: '1.2rem' }}
        >
          Our students are going to be the pioneers of 21st-century education. Creating a 360-degree child development environment.
        </h2>

        <div>
          <Button
            variant="primary"
            onClick={handleShow}
            style={{ backgroundColor: 'rgb(10, 68, 127)' }}
          >
            Enquiry
          </Button>

          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Write to us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form ref={form} onSubmit={sendEmail}>
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="name4">
                    Name
                  </label>
                  <input type="text" id="name4" className="form-control" name="name" required />
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="email4">
                    Email address
                  </label>
                  <input type="email" id="email4" className="form-control" name="email" required />
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="dropdownOptions">
                    Query
                  </label>
                  <select
                    id="dropdownOptions"
                    className="form-select"
                    name="query"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    required
                  >
                    <option value="">Select One</option>
                    <option value="Admission">Admission</option>
                    <option value="Curriculum">Curriculum</option>
                    <option value="Facilities">Facilities</option>
                    <option value="Others">Others</option>
                  </select>
                </div>

                <button type="submit" className="btn btn-primary btn-block">
                  Send
                </button>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Enquiry;
