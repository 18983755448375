import React from 'react'
import Management from '../component/management/Index'
import Navbar from '../component/Upgraded/Navbar'
import Footer from '../component/footer/Index'
import Mhero from '../component/hero/Mhero'


const ManagementPage = () => {
  return (
    <>
      <Navbar />
      <Mhero/>
      <Management />
      <Footer />
    </>
  )
}

export default ManagementPage